import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/challenge-1/",
  "date": "2020-05-08T00:00:00.000Z",
  "title": "Blog, Welcome",
  "tags": ["intro", "welcome"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Hello world,`}</h2>
    <p>{`Welcome to my Gatsby blog.`}</p>
    <p>{`Please see my about page.`}</p>
    <p>{`We do hope you enjoy your stay.`}</p>
    <p><img parentName="p" {...{
        "src": "https://media.giphy.com/media/5zf2M4HgjjWszLd4a5/giphy.gif",
        "alt": "--"
      }}></img></p>
    <p><a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/blog/tags/100-days-of-gatsby"
      }}>{`#100DaysOfGatsby`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      